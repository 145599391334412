export default {
    menu: 'Menu',
    'menu/home': 'Home',
    'menu/diet': 'Dieta',
    'menu/fav-meals': 'Ulubione posiłki',
    'menu/diet-plan': 'Plan diety',
    'menu/shop': 'Sklep',
    'menu/knowledge': 'Wiedza',
    'menu/blog': 'Blog',
    'menu/contact': 'Kontakt',

    'menu/activities': 'Aktywności',
    'menu/shopping-list': 'Lista zakupów',
    'menu/measurements': 'Pomiary',
    'menu/diet/settings': 'Ustawienia diety',

    'menu/logout': 'Wyloguj się',
    'menu/login': 'Zaloguj się',
    'menu/settings': 'Ustawienia',
    'menu/account': 'Konto',

    FAST_TYPE_CLASSIC: 'klasyczny',
    FAST_TYPE_SIMPLE: 'prosty',

    logout: 'Wyloguj',
    save: 'Zapisz',
    cancel: 'Anuluj',
    insert: 'Wstaw',
    ok: 'OK',
    delete: 'Usuń',
    add: 'Dodaj',
    next: 'Dalej',
    back: 'Wróć',
    print: 'Drukuj',
    'print-header': 'Dieta dr Ewy Dąbrowskiej',
    'print-copyright1':
        'Plan diety przygotowany dla {{email}}. Plan diety chroniony prawami autorskimi.',
    'print-copyright2':
        'Rozpowszechnianie utworu bez zgody tworcy lub osoby posiadajacej prawa autorskie do utworu jest karane na podstawie art. 116 ust. o prawie autorskim i prawach pokrewnych.',
    close: 'zamknij',
    buy: 'Kup',
    'data-saved': 'Zapisano',
    'error/message/generic': 'Coś poszło nie tak',
    'error/message/network': 'Wystąpił błąd sieci',
    'error/message/offline': 'Brak połączenia z siecią',
    'error/message/no-account': 'Nie masz jeszcze konta?',
    'error/additional-message/generic':
        'Incydent został zgłoszony, będziemy pracować nad jego rozwiązaniem.',
    'error/additional-message/not-found':
        'Strona o podanym adresie nie istnieje.',
    'error/additional-message/user-not-found':
        'Nie znaleźliśmy twojego konta wśród zarejestrowanych użytkowników.',
    'button/refresh': 'Odśwież',
    'button/try-again': 'Spróbuj ponownie',
    'button/go-back': 'Powrót',
    'button/get-access': 'Uzyskaj dostęp',
    'button/send-email': 'Wyślij e-mail',
    'button/retry': 'Ponów',
    'button/remove-account': 'Usuń konto',

    week: 'tydzień',
    week_0: 'tydzień',
    week_1: '{{count}} tygodnie',
    week_2: '{{count}} tygodni',
    week_3: '{{count}} tygodnia',

    month: 'miesiąc',
    month_0: 'miesiąc',
    month_1: '{{count}} miesiące',
    month_2: '{{count}} miesięcy',
    month_3: '{{count}} miesiąca',

    year: 'rok',
    year_0: 'rok',
    year_1: '{{count}} lata',
    year_2: '{{count}} lat',
    year_3: '{{count}} roku',

    'diet-intro/title': 'Przeczytaj zanim zaczniesz',

    'diet-intro/p1/title': 'Ureguluj godziny spożywanych posiłków',
    'diet-intro/p1/content':
        'Postaraj się dopasować je do własnego rytmu dnia. Wyrobienie nawyku regularnego spożywania posiłków co około 2-2,5 godzin pozwoli Twojemu organizmowi uruchomić niezbędne procesy trawienne.',

    'diet-intro/p2/title': 'Zaplanuj swoje okno żywieniowe',
    'diet-intro/p2/content':
        'Postaraj się spożywać posiłki w oknie żywieniowym, np. 10/14 - 10 godzin to czas przeznaczony na jedzenie, 14 godzin to czas przeznaczony na regenerację sił Twojego organizmu.',

    'diet-intro/p3/title': 'Wyeliminuj żywność przetworzoną',
    'diet-intro/p3/content':
        'Usuń ze swojego jadłospisu żywność o długim terminie przydatności do spożycia, czyli bogatą w konserwanty, sztuczne barwniki i dodatki. Unikaj słodkich napojów, słodyczy, słonych przekąsek, chipsów, kiełbas i parówek. Taka żywność jest uboga w wartości odżywcze, a dodatkowo zaburza pracę Twojego organizmu.',

    'diet-intro/p4/title': 'Wybieraj produkty od lokalnych producentów',
    'diet-intro/p4/content':
        'Korzystaj z żywności ekologicznej, kupowanej od lokalnych producentów, na bazarach. Nie tylko z owoców i warzyw, ale również mięsa, ryb, nabiału i jaj. Korzystaj z certyfikowanych źródeł, wolnych od nawozów, antybiotyków i pestycydów. Czytaj etykiety kupowanych produktów - im krótszy skład, tym bardziej wartościowy produkt.',

    'diet-intro/p5/title': 'Wprowadź warzywa do każdego posiłku',
    'diet-intro/p5/content':
        'Przy każdym posiłku zadbaj o dodatek warzyw. Najlepiej, aby były one podawane przede wszystkim w formie surowej, dzięki czemu zachowasz ich cenne wartości odżywcze.',

    'diet-intro/p6/title': 'Jedz kiszonki',
    'diet-intro/p6/content':
        'Kiszonki są elementem zapewniającym prawidłową pracę jelit. Jako naturalny probiotyk wspierają Twoją odporność, poprawiają trawienie oraz są źródłem witamin i składników mineralnych takich jak: witaminy z grupy B, witamina C czy antyoksydantów.',

    'diet-intro/p7/title': 'Zaopatrz się w zioła',
    'diet-intro/p7/content':
        'Zioła spożywaj w postaci naparów, ale nie zapominaj również o przyprawach, zarówno tych świeżych jak i suszonych. Mają one ogromną moc nadania smaku potrawom, ale przyczynią się również do usprawnienia pracy Twojego przewodu pokarmowego.',

    'diet-intro/p8/title': 'Zadbaj o nawodnienie organizmu',
    'diet-intro/p8/content':
        'W ciągu dnia wypijaj około 1,5 l wody. W fazie postu postaraj się, aby ilość spożywanych płynów była zwiększona do około 3 l. Zadbaj, aby większość z nich stanowiła woda, co pozwoli na skuteczne usuwanie toksyn z organizmu. Nie popijaj posiłków, pij przynajmniej 30 minut przed posiłkiem lub 30 minut po jego spożyciu. To podstawa dla każdego, kto chce usprawnić swój metabolizm i efektywnie przejść przez post.',

    'diet-intro/start-diet': 'Rozpocznij dietę',

    share: 'Udostępnij',
    OK: 'OK',
    'share/title': 'Udostępnij swoje postępy',
    'share/description':
        'Udostępnij grafikę na Facebooku i/lub Instagramie, aby pokazać znajomym jak dobrze idzie Ci post!',
    'share/how-to-read': 'Jak czytać status?',
    'share/current-diet-progress': 'dzień postu',
    'share/current-diet-length': 'zaplanowana ilość dni postu',
    'share/diets-count': 'który to Twój post',

    'meal/preparation-time': 'Czas',
    'diet/meal/seasoning/header': 'Przyprawy',
    'diet/meal/optionalIngredients/header': 'Do potrawy możesz dodać również',
    'meal/time': 'Czas:',
    'meal/min': 'min',
    'meal/how-to-prepare': 'Sposób przygotowania',
    'meal/proteins-short': 'B',
    'meal/fat-short': 'T',
    'meal/carbohydrates-short': 'W',
    'meal/proteins': 'Białka',
    'meal/fat': 'Tłuszcze',
    'meal/carbohydrates': 'Węglowodany',
    'meal/weekday0': 'Niedziela',
    'meal/weekday1': 'Poniedziałek',
    'meal/weekday2': 'Wtorek',
    'meal/weekday3': 'Środa',
    'meal/weekday4': 'Czwartek',
    'meal/weekday5': 'Piątek',
    'meal/weekday6': 'Sobota',
    'meal/weekday0-short': 'Nd.',
    'meal/weekday1-short': 'Pn.',
    'meal/weekday2-short': 'Wt.',
    'meal/weekday3-short': 'Śr.',
    'meal/weekday4-short': 'Cz.',
    'meal/weekday5-short': 'Pt.',
    'meal/weekday6-short': 'Sb.',
    'meal/exchange-meal-button': 'Wymień posiłek',
    'meal/exchange-dish-button': 'Wymień',
    'meal/exchange-collapse': 'Zwiń',
    'meal/exchange-day-button': 'Wymień dzień',
    'meal/exchange-ingredient': 'Wymień składnik',
    'meal/exchange-for': 'Możesz wymienić na',
    'meal/original-product': 'Oryginalny produkt',
    'meal/original-meal': 'Oryginalny posiłek',
    'meal/original-day': 'Oryginalny jadłospis',
    'meal/summary-title': 'Rozkład kaloryczności',
    'meal/print-button': 'Drukuj',
    'meal/print-period-alert':
        'Wydruk dostępny dla okresu krótszego ni 2 tygodnie.',
    'meal/show-menus-contain': 'Pokaż jadłospisy zawierające:',
    'meal/menus-contain': 'zawiera:',
    'meal/search': 'Szukaj',
    'meal/name-of-dish-or-ingredient': 'nazwa dania lub składnika',
    'meal/there-are-no-replacements': 'Brak odpowiednich zamienników.',
    'meal/supports': 'Wspiera:',
    'next-day-notification': 'Na jutro przygotuj:',
    'meal/copy-day-button': 'Kopiuj na jutro',
    'meal/copy-day-button-done': 'Skopiowany',
    'meal/favourite-meals': 'Ulubione posiłki',
    'meal/show-favourite-meals': 'Wyszukaj ulubiony posiłek:',
    'meal/favourite-meals/no-meals':
        'Dodawaj posiłki do ulubionych, aby mieć je zawsze pod ręką.',
    'meal/show-meal-details': 'Szczegóły',
    'meal/show-only-current-phase': 'Dania zgodne z aktualną fazą diety',
    'meal/meal-details': 'Szczegóły posiłku',
    'meal/favourite-meals/no-search-results':
        'Brak wyników wśród szukanych posiłków',
    'meal/eaten-button': 'Zjedzone',

    'holiday-notification': 'Pokaż <u>specjalny jadłospis</u> dla tego dnia.',
    'show-special-menu': 'Pokaż jadłospis',
    fast: 'Post',
    'fast/exchange-day/alert':
        'Dana alternatywa postu dostępna jest tylko przez 3 dni, aby przez pozostały czas zachować możliwie zróżnicowany jadłospis umożliwiający odżywianie wewnątrzkomórkowe.',

    'dish/portion/you-should-have-this-dish-last':
        '[Powinnaś|Powinieneś] mieć przygotowaną tą potrawę już wcześniej. Jeśli coś Ci zostało, nie będzie to już uwzględnione w Twoim planie diety.',
    'dish/portion/you-should-have-this-dish':
        '[Powinnaś|Powinieneś] mieć przygotowaną tą potrawę już wcześniej.',

    'dish/portion/rest': 'Reszta nie zostanie użyta w Twoim planie diety.',

    'dish/portion/eat-x-of': 'Zjedz jedną porcję z {{total}}.',
    'dish/portion/eat-x-of_0': 'Zjedz {{fraction}}&nbsp;porcję z {{total}}.',
    'dish/portion/eat-x-of_1': 'Zjedz {{fraction}}&nbsp;porcje z {{total}}.',
    'dish/portion/eat-x-of_2': 'Zjedz {{fraction}}&nbsp;porcji z {{total}}.',
    'dish/portion/eat-x-of_3': 'Zjedz {{fraction}}&nbsp;porcji z {{total}}.',

    'dish/portion/eat-x-portion': 'Zjedz jedną porcję.',
    'dish/portion/eat-x-portion_0': 'Zjedz {{fraction}}&nbsp;porcję.',
    'dish/portion/eat-x-portion_1': 'Zjedz {{fraction}}&nbsp;porcje.',
    'dish/portion/eat-x-portion_2': 'Zjedz {{fraction}}&nbsp;porcji.',
    'dish/portion/eat-x-portion_3': 'Zjedz {{fraction}}&nbsp;porcji.',

    'dish/portion/x-portion-of': ' - jedna porcja z {{total}}',
    'dish/portion/x-portion-of_0': ' - {{fraction}}&nbsp;porcja z {{total}}',
    'dish/portion/x-portion-of_1': ' - {{fraction}}&nbsp;porcje z {{total}}',
    'dish/portion/x-portion-of_2': ' - {{fraction}}&nbsp;porcji z {{total}}',
    'dish/portion/x-portion-of_3': ' - {{fraction}}&nbsp;porcji z {{total}}',

    'recipe-for-x-portion': 'Przepis na jedną porcję',
    'recipe-for-x-portion_0': 'Przepis na {{count}} porcję',
    'recipe-for-x-portion_1': 'Przepis na {{count}} porcje',
    'recipe-for-x-portion_2': 'Przepis na {{count}} porcji',
    'recipe-for-x-portion_3': 'Przepis na {{count}} porcji',

    'dish/portion/dish-used-in-following-days':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na jedną porcję.',
    'dish/portion/dish-used-in-following-days_0':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcję.',
    'dish/portion/dish-used-in-following-days_1':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcje.',
    'dish/portion/dish-used-in-following-days_2':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcji.',
    'dish/portion/dish-used-in-following-days_3':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcji.',

    'shopping-list/title': 'Lista zakupów',
    'shopping-list/export-to-frisco': 'Zapisz jako listę zakupów Frisco.pl',
    'shopping-list/date-range': 'Zakres dat',
    'shopping-list/show-list-button': 'Przygotuj listę zakupów',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'Nie masz ułożonej diety na cały zakres dat. Lista zakupów zawiera produkty tylko z dni w których dieta została ułożona.',
    'shopping-list/alert/no-diet-in-period':
        'Brak ułożonej diety w podanym zakresie dat.',
    'shopping-list/bought': 'Kupione',
    'shopping-list/no-products': 'Brak produktów',

    'measurments/title': 'Pomiary',
    'measurments/history': 'Historia',
    'measurments/current-measurments-title': 'Twoje aktualne pomiary',
    'measurments/edit-measurments-title': 'Edytuj pomiary',
    'measurments/add-measurments-button': 'Dodaj pomiary',
    'measurments/delete-measurment-button': 'Usuń aktualny pomiar',
    'measurments/date': 'Data',
    'measurments/alert-no-measurments-in-period':
        'Brak pomiarów danego typu w danym okresie czasu',
    'measurments/param/body-weight': 'Masa ciała',
    'measurments/param/hips': 'Biodra',
    'measurments/param/hip': 'Biodro',
    'measurments/param/waist': 'Talia',
    'measurments/param/thighs': 'Uda',
    'measurments/param/thigh': 'Udo',
    'measurments/param/chest': 'Klatka',
    'measurments/param/arm': 'Ramię',
    'measurments/state/no-measurement': 'brak pomiaru',
    'measurments/state/change': 'zmiana',
    'measurments/state/no-change': 'bez zmian',

    'no-data': 'brak danych',
    'no-change': 'bez zmian',

    'redirect/diet-settings/title': 'Plan diety',
    'redirect/diet-settings/message':
        'Nie masz jeszcze rozpoczętego planu diety.',
    'redirect/shopping-list/message':
        'Rozpocznij dietę, aby zobaczyć listę zakupów.',
    'redirect/diet-settings/button': 'Rozpocznij dietę',

    'diet-settings/title': 'Ustawienia diety',
    'diet-settings/sex': 'Płeć',
    'diet-settings/sex/male': 'Mężczyzna',
    'diet-settings/sex/female': 'Kobieta',
    'diet-settings/name': 'Imię',
    'diet-settings/current-body-weight': 'Aktualna masa ciała:',
    'diet-settings/height': 'Wzrost:',
    'diet-settings/birth-date': 'Data urodzenia:',
    'diet-settings/your-goal': 'Twój cel',
    'diet-settings/goal/slimming': 'Zmniejszenie masy ciała',
    'diet-settings/goal/stabilization': 'Utrzymanie masy ciała',
    'diet-settings/goal/put-on-weight': 'Zwiększenie masy ciała',
    'diet-settings/goal/gain-muscle-mass': 'Budowanie masy mięśniowej',
    'diet-settings/goal-weight': 'Docelowa masa ciała',
    'diet-settings/diet-type/vege': 'Dieta Wege',
    'diet-settings/diet-type/vege/description':
        'Wyklucza mięso, ryby i owoce morza, pszenicę i jej pochodne oraz mleko krowie. Uwzględnia natomiast sery, produkty mleczne fermentowane, jaja i nasiona roślin strączkowych.',
    'diet-settings/activity/question': 'Ile średnio ćwiczysz w tygodniu?',
    'diet-settings/activity/none':
        'ćwiczę sporadycznie lub w ogóle — praca biurowa',
    'diet-settings/activity/low': 'ćwiczę 1-3 godziny tygodniowo',
    'diet-settings/activity/medium':
        'ćwiczę 3-5 godzin w tygodniu (ćwiczenia siłowe)',
    'diet-settings/activity/high':
        'ćwiczę 3-5 godzin tygodniowo (ćwiczenia aerobowe, biegi, spining, itp.)',
    'diet-settings/final/text':
        'Czym kierować się przy rozpoczęciu lub zmianie planu diety?',
    'diet-settings/final/list/1':
        'zaplanuj czas na dokonanie zakupów potrzebnych do diety',
    'diet-settings/final/list/2':
        'jeżeli planujesz wyjazd, który uniemożliwi Tobie zastosowanie się do jadłospisu, przełóż start diety na dogodniejszy termin.',
    'diet-settings/final/list/3':
        'nie odwlekaj terminu rozpoczęcia diety — skoro zrobiłeś już pierwszy krok, idź śmiało dalej',
    'diet-settings/final/list/4': 'masz 15 dni na rozpoczęcie diety',
    'diet-settings/final/start-date-question':
        'Od kiedy chcesz rozpocząć dietę lub dokonać w niej zmian?',
    'diet-settings/welcome-user': 'Witaj',
    'diet-settings/welcome-message':
        'Odpowiedz na kilka pytań, które pomogą nam przygotować plan diety odpowiedni dla Ciebie.',
    'diet-settings/cta': 'Rozpocznij',
    'diet-settings/acceptance/title': 'Stosowanie diety',
    'diet-settings/acceptance/msg1':
        'Diety nie należy stosować w przypadku występowania lęku przed ograniczeniem jedzenia, stosowania hormonów sterydowych i cytostatyków, a także niewydolności nadnerczy, ciężkiej depresji, stanów po przeszczepach narządów, odbywania chemioterapii, krańcowej marskości wątroby, dializy nerek, nadczynności tarczycy, cukrzycy typu I, ostrej porfirii, ciąży, osób nieletnich oraz podczas karmienia piersią.',
    'diet-settings/acceptance/msg2':
        'W przypadku zażywania leków, cukrzycy typu 2, choroby nerek, choroby wątroby, choroby tarczycy, wrzodów żołądka i dwunastnicy, kamieni nerkowych, podwyższonych wartości wyników laboratoryjnych: prób wątrobowych (ALAT, AspAT), kreatyniny, niedoborów pokarmowych, niedokrwistości, chorób dietozależnych oraz innych chorób przewlekłych i ostrych dietę należy stosować za zgodą i pod nadzorem lekarza.',
    'diet-settings/acceptance/msg3':
        'Zalecenia zawarte w aplikacji mają wyłącznie charakter informacyjny i nie stanowią porady lekarskiej, a stosowanie ich w praktyce nie zastąpi indywidualnej wizyty lekarskiej z lekarzem specjalistą lub też specjalistą-dietetykiem.',
    'diet-settings/acceptance/acceptance':
        'Oświadczam, że zapoznałem się z przeciwwskazaniami i nie choruję na wyżej wymienione jednostki chorobowe lub będę stosować dietę za zgodą i pod nadzorem lekarza',
    'diet-settings/acceptance/confirm': 'Potwierdzam',
    'diet-settings/diet-programme/label': 'Wybierz zakres diety:',
    'diet-settings/diet-programme/full/title': 'Pełny program żywieniowy',
    'diet-settings/diet-programme/full/description':
        'Obejmuje: Przygotowanie do postu, Post, Wychodzenie z postu oraz Zdrowe odżywianie',
    'diet-settings/diet-programme/fast-preparation/title':
        'Przygotowanie do postu',
    'diet-settings/diet-programme/fast-preparation/description':
        'Pojedynczy etap',
    'diet-settings/diet-programme/fast/title': 'Post',
    'diet-settings/diet-programme/fast/description': 'Pojedynczy etap',
    'diet-settings/diet-programme/fast-exit/title': 'Wychodzenie z postu',
    'diet-settings/diet-programme/fast-exit/description': 'Pojedynczy etap',
    'diet-settings/diet-programme/healthy-eating/title': 'Zdrowe odżywianie',
    'diet-settings/diet-programme/healthy-eating/description':
        'Pojedynczy etap',
    'diet-settings/fast-days/title': 'Ustaw czas trwania etapu:',
    'diet-settings/fast-days/fast-preparation/title': 'Przygotowanie do postu',
    'diet-settings/fast-days/fast-preparation/info':
        'Aby dobrze przygotować się do postu, okres ten powinien trwać minimum 3 dni',
    'diet-settings/fast-days/fast/title': 'Post',
    'diet-settings/fast-days/fast/info':
        'Sugerowany okres postu to minimum 7 dni',
    'diet-settings/fast-days/days': 'dni',

    'diet-settings/fast-type/label': 'Jaki rodzaj postu chcesz stosować?',
    'diet-settings/fast-type/simple/title': 'Post prosty',
    'diet-settings/fast-type/simple/description':
        'Oparty o szybkie w przygotowaniu dania warzywno-owocowe. W tym wariancie będziesz jeść przede wszystkim smaczne surówki, zielone koktajle oraz proste dania jednogarnkowe. To uproszczona wersja postu, dzięki której spędzisz mniej czasu w kuchni.',
    'diet-settings/fast-type/classic/title': 'Post klasyczny',
    'diet-settings/fast-type/classic/description':
        'Oparty o dania warzywno-owocowe. Zawiera posiłki złożone składające się zarówno z dań surowych, zielonych koktajli, jak i potraw gotowanych czy pieczonych. To tradycyjna wersja postu dr Ewy Dąbrowskiej.',

    'diet-settings/diet-type/label': 'Jaki rodzaj diety chcesz stosować?',
    'diet-settings/diet-type/label/stage': 'Zdrowe odżywianie',
    'diet-settings/diet-type/mediterranean-diet/title':
        'Dieta śródziemnomorska',
    'diet-settings/diet-type/mediterranean-diet/description':
        'Oparta na warzywach i owocach, zawierająca produkty zbożowe pełnoziarniste i zdrowe tłuszcze, ograniczająca spożycie mięsa i produktów mlecznych.',
    'diet-settings/diet-type/elimination-diet/title': 'Dieta eliminacyjna',
    'diet-settings/diet-type/elimination-diet/description':
        'Dieta z wykluczeniem glutenu, mleka i nabiału oraz jaj.',
    'diet-settings/diet-type/vegetarian-diet/title': 'Dieta wegetariańska',
    'diet-settings/diet-type/vegetarian-diet/description':
        'Dieta z wykluczeniem mięsa, ryb i owoców morza, bazująca na produktach pochodzenia roślinnego. Źródłem białka są nasiona roślin strączkowych, jaja oraz umiarkowane ilości przetworów z mleka krowiego i koziego.',
    'diet-settings/fast-day-of-the-week/label':
        'Wybierz dzień tygodnia, w którym chcesz stosować 1-dniowy post na etapie:',
    'diet-settings/fast-day-of-the-week/mon': 'Pn',
    'diet-settings/fast-day-of-the-week/tue': 'Wt',
    'diet-settings/fast-day-of-the-week/wed': 'Śr',
    'diet-settings/fast-day-of-the-week/thur': 'Czw',
    'diet-settings/fast-day-of-the-week/fri': 'Pt',
    'diet-settings/fast-day-of-the-week/sat': 'Sb',
    'diet-settings/fast-day-of-the-week/sun': 'Nd',
    'diet-settings/fast-day-of-the-week/info':
        'Wybór możesz później zmienić w ustawieniach.',
    'diet-settings/sex/label': 'Twoja płeć:',
    'diet-settings/goal/label': 'Cel stosowania diety',
    'diet-settings/work-type/label':
        'Jak określisz poziom swojego ruchu w ciągu dnia? (nie dotyczy treningów)',
    'diet-settings/work-type/low/title': 'Mało',
    'diet-settings/work-type/low/description':
        'praca w domu, podróżowanie wyłącznie samochodem',
    'diet-settings/work-type/medium/title': 'Średnio',
    'diet-settings/work-type/medium/description':
        'praca siedząca, różne środki transportu',
    'diet-settings/work-type/high/title': 'Dużo',
    'diet-settings/work-type/high/description':
        'praca fizyczna, więcej chodzę, niż jeżdżę',
    'diet-settings/activity-level/label': 'Przez ile dni w tygodniu ćwiczysz?',
    'diet-settings/activity-level/none':
        'Ćwiczę rzadziej, niż 1 dzień w tygodniu',
    'diet-settings/activity-level/low': 'Ćwiczę 1-3 dni w tygodniu',
    'diet-settings/activity-level/medium': 'Ćwiczę 4-5 dni w tygodniu',
    'diet-settings/activity-level/high': 'Ćwiczę 6-7 dni w tygodniu',
    'diet-settings/finish':
        'Dziękujemy. Nasi dietetycy układają Twój osobisty plan diety.',

    'settings/title': 'Ustawienia',
    'settings/profile': 'Profil użytkownika',
    'settings/email': 'Adres e-mail',
    'settings/metric-system': 'System metryczny',
    'settings/si': 'System SI (kg, cm)',
    'settings/imperial': 'System imperialny (cale, funty)',
    'settings/notifications': 'Powiadomienia',
    'settings/agreements/diet-course': 'Dotyczące przebiegu diety',
    'settings/agreements/articles': 'Motywacja i artykuły',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Wypisanie się z newslettera może zająć do 2 godzin.',
    'settings/change-password': 'Zmień hasło',
    'settings/change-password/current-password': 'Obecne hasło',
    'settings/change-password/new-password': 'Nowe hasło',
    'settings/change-password/success': 'Hasło zostało zmienione',
    'settings/products/access/end-date {date}': 'Dostęp opłacony do {date}.',
    'settings/products/access/past-end-date {date}': 'Dostęp wygasł {date}.',
    'settings/products/access/from-subscription':
        'Dostęp na podstawie subskrypcji.',
    'settings/products/access/lifetime': 'Dostęp dożywotni.',
    'settings/products/access/none': 'Brak.',
    'settings/products/diet/heading': 'Dieta',
    'settings/products/heading': 'Aktywne produkty',
    'settings/subscriptions/cancellation-instructions': 'Instrukcja anulowania',
    'settings/subscriptions/heading': 'Aktywne subskrypcje',
    'settings/subscriptions/diet/heading': 'Dieta',
    'settings/subscriptions/none': 'Brak.',
    'settings/subscriptions/renewal-date {date}':
        'Następne odnowienie: {date}.',
    'settings/subscriptions/renewal-date/in-past':
        'Odnowienie w trakcie przetwarzania.',
    'settings/subscriptions/type/diets': 'Dostęp do diety.',
    'settings/hydration': 'Nawodnienie',
    'settings/hydration/daily-goal': 'Dzienny cel',
    'settings/hydration/success': 'Cel zmieniony',
    'settings/hydration/notifications': 'Przypomnienia o piciu wody',
    'settings/hydration/notifications/hour-interval': 'Co ile godzin',
    'settings/hydration/notifications/from-hour': 'Od godziny',
    'settings/hydration/notifications/to-hour': 'Do godziny',
    'settings/hydration/notifications/use-sound': 'Z dźwiękiem',
    'settings/remove-account': 'Usuwanie konta',

    'remove-account/title': 'Usuwanie konta',
    'remove-account/app-store/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach konta Apple ID na swoim iPhonie.',
    'remove-account/google-play/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach aplikacji Google Play.',
    'remove-account/email-confirm-info':
        'Proces usuwania konta wymaga weryfikacji Twojego konta. W tym celu wyślemy do Ciebie e-mail na adres:  {{userEmail}}',
    'remove-account/message-sent': 'Wiadomość wysłana',
    'remove-account/link-expire-info': 'Link wygaśnie po 24 godzinach.',
    'remove-account/check-your-mailbox':
        'Sprawdź swoją skrzynkę e-mail. Kliknij link w wiadomości, którą do Ciebie wysłaliśmy. <br /> <b>Twoje konto nie zostanie usunięte, dopóki tego nie zrobisz.</b>',
    'remove-account/confirmation':
        'Potwierdzam, chcę usunąć swoje konto z aplikacji.',
    'remove-account/info-title': 'Po usunięciu konta:',
    'remove-account/info_1':
        'nie zalogujesz się na swoje konto, przez co stracisz dostęp do aplikacji',
    'remove-account/info_2':
        'utracisz wszystkie swoje postepy, historię, nagrody itd. dotyczączące treningów, diety i pozostałych aktywności w aplikacji',
    'remove-account/info_3':
        'dane dotyczące twojego konta zostaną bezpowrotnie usunięte',
    'remove-account/error': 'Wystąpił błąd',
    'remove-account/try-in-moment': 'Spróbuj ponownie za chwilę.',
    'remove-account/link-expired': 'Ważność linka wygasła.',
    'remove-account/link-expired-info':
        'Ponów w aplikacji proces usuwania konta, aby otrzymać nową wiadomość e-mail.',
    'remove-account/success': 'Twoje konto zostało usunięte',
    'remove-account/success-info':
        'Za moment zostaniesz automatycznie wylogowany z aplikacji.',

    'footer/service-access': 'Dostęp do usługi i płatności',
    'footer/tos': 'Regulamin',
    'footer/privacy-policy': 'Polityka prywatności',
    'footer/contact': 'Kontakt',
    'footer/faq': 'Pytania i odpowiedzi',
    'footer/secure-payments': 'Bezpieczne płatności zapewnia',
    'button/buy-diet': 'Kup dostęp do diety',
    'button/buy-subscribtion': 'Kup subskrypcję',
    'button/buy': 'Kup',

    'error/unauthorized/subscription/expired/title':
        'Twoja subskrypcja wygasła\n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'Kontynuuj swoją dietę korzystając z oferty dla stałych klientów.',
    'error/unauthorized/subscription/never-had/title':
        'Rozpocznij swoją dietę już teraz!',
    'error/unauthorized/subscription/never-had/content':
        'Tylko jedna decyzja dzieli Cię od rozpoczęcia Diety dr Ewy Dąbrowskiej. Nie trać czasu, gdyż cel jest w zasięgu Twojej ręki!',
    'error/unauthorized/subscription/refresh': 'Sprawdź ponownie',
    'error/unauthorized/subscription/restore-purchuases':
        'Przypisz zakupioną subskrypcję',

    'info-pages/goal-reached/title': 'Brawo! [Osiągnęłaś|Osiągnąłeś] swój cel',
    'info-pages/goal-reached/content':
        'Twój cel został osiągnięty! Czas na decyzję, co chcesz zrobić dalej.',
    'info-pages/goal-reached/button': 'Dostosuj plan diety',

    'info-pages/goal-lost/title': 'Niestety! Twój cel nie został osiągnięty.',
    'info-pages/goal-lost/content':
        'Jeśli chcesz wrócić do swojej docelowej masy ciała, proponujemy przejście do diety odchudzającej. ',
    'info-pages/goal-lost/button': 'Zmień plan diety',

    'info-pages/current-weight-needed/title': 'Dodaj aktualny pomiar',
    'info-pages/current-weight-needed/content':
        'W celu przygotowania dalszego planu diety musisz dodać aktualny pomiar masy ciała.',
    'info-pages/current-weight-needed/button': 'Dodaj pomiar',

    'info-pages/diet-available-in-future/title':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_0':
        'Twoja dieta będzie dostępna za {{count}} dzień',
    'info-pages/diet-available-in-future/title_1':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_2':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_3':
        'Twoja dieta będzie dostępna za {{count}} dnia',
    'info-pages/diet-available-in-future/content':
        'Nowy plan diety może być przygotowany najwcześniej na 7 dni przed rozpoczęciem. Pamiętaj, aby w tym czasie, dokonać aktualnego pomiaru masy ciała.',

    'info-pages/diet-outside-access/title':
        'Twoja subskrypcja kończy się za jeden dzień, {{date}}',
    'info-pages/diet-outside-access/title_0':
        'Twoja subskrypcja kończy się za {{count}} dzień, {{date}}',
    'info-pages/diet-outside-access/title_1':
        'Twoja subskrypcja kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/title_2':
        'Twoja subskrypcja kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/title_3':
        'Twoja subskrypcja kończy się za {{count}} dnia, {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'nie wiadomo ile',

    'info-pages/diet-outside-access/web-title':
        'Twój abonament kończy się za jeden dzień, {{date}}',
    'info-pages/diet-outside-access/web-title_0':
        'Twój abonament kończy się za {{count}} dzień, {{date}}',
    'info-pages/diet-outside-access/web-title_1':
        'Twój abonament kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/web-title_2':
        'Twój abonament kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/web-title_3':
        'Twój abonament kończy się za {{count}} dnia, {{date}}',

    'info-pages/diet-completed/now':
        '[Zakończyłaś|Zakończyłeś] {{phase}}. Wybierz, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future':
        '{{phase}} kończy się za {{count}} dzień. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_0':
        '{{phase}} kończy się za {{count}} dzień. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_1':
        '{{phase}} kończy się za {{count}} dni. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_2':
        '{{phase}} kończy się za {{count}} dni. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_3':
        '{{phase}} kończy się za {{count}} dnia. Już teraz możesz wybrać, co chcesz robić dalej.',

    'info-pages/after-long-break':
        'Dawno Cię z nami nie było. [Zakończyłaś|Zakończyłeś] {{phase}}. Wybierz, co chcesz robić dalej.',

    'contact/title': 'Kontakt',
    'contact/faq': 'Najczęściej zadawane pytania',
    'contact/contact-form': 'Formularz kontaktowy',
    'contact/name-surname': 'Imię i nazwisko',
    'contact/email-address': 'Twój adres e-mail',
    'contact/topic': 'Temat wiadomości',
    'contact/topic/topic1': 'Problem z zakupem planu diety',
    'contact/topic/topic2': 'Pytanie do dietetyka',
    'contact/topic/topic4': 'Problem z dostępem do serwisu',
    'contact/topic/topic5': 'Plan diety - reklamacje i zwroty',
    'contact/topic/topic6': 'Sklep internetowy - reklamacje i zwroty',
    'contact/topic/topic7': 'Współpraca',
    'contact/message': 'Wiadomość',
    'contact/send-message': 'Wyślij wiadomość',
    'contact/send-success': 'Wiadomość została wysłana. Dziękujemy.',

    'knowledge/title': 'Wiedza',
    'knowledge/show': 'Pokaż:',
    'knowledge/all': 'Wszystko',
    'knowledge/diet': 'Dieta',
    'knowledge/motivation': 'Motywacja',
    'knowledge/tips': 'Porady',
    'knowledge/lectures': 'Wykłady',
    'knowledge/no-results': 'Brak wyników.',
    'knowledge/back-to-article-list': 'Powrót do listy artykułów',
    'knowledge/no-access':
        'Pełna treść artykułu dostępna tylko dla użytkowników z aktywnym planem diety.',
    'knowledge/load-more': 'Więcej artykułów',

    'me/account/activate/message': 'Twoje konto zostało aktywowane.',

    'make-decision/title': 'Podejmij decyzję',
    'make-decision/start': 'Start',
    'make-decision/now': 'Obecnie',
    'make-decision/goal': 'Cel',
    'make-decision/left': 'Zostało',
    'make-decision/put-on-weight': 'Przytycie',
    'make-decision/gain-muscle-mass': 'Budowanie mięśni',
    'make-decision/weight-loose': 'Odchudzanie',
    'make-decision/stabilization': 'Stabilizacja',
    'make-decision/continue-slimming':
        'Jeśli zależy Ci na dalszej utracie kilogramów, wpisz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        'Udało Ci się osiągnąć docelową masę ciała. Żeby utrzymać te efekty, wybierz datę rozpoczęcia etapu stabilizującego.',
    'make-decision/continue-stabilization':
        'Jeżeli pomimo zmiany masy ciała nadal chcesz stosować dietę stabilizującą, kliknij w poniższy przycisk. Pamiętaj że wybranie tej opcji spowoduje, że ostatnia wartość dodanego pomiaru masy ciała zostanie ustawiona, jako Twoja obecna masa docelowa.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Ponieważ Twoja masa ciała wzrosła, rekomendujemy przejście do diety odchudzającej. Wpisz nową masę docelową oraz wybierz termin rozpoczęcia diety.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Ponieważ Twoja masa ciała zmalała, rekomendujemy przejście do diety na przytycie. Wpisz nową masę docelową oraz wybierz termin rozpoczęcia diety.',
    'make-decision/continue-put-on-weight':
        'Jeśli chcesz kontynuować dietę na przytycie wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'Udało Ci się osiągnąć docelową masę ciała. Żeby utrzymać te efekty, wybierz datę rozpoczęcia etapu stabilizującego. ',
    'make-decision/continue-gain-muscle-mass':
        'Jeśli chcesz kontynuować budowanie masy mięśniowej wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'Udało Ci się osiągnąć docelową masę ciała. Żeby utrzymać te efekty, wybierz datę rozpoczęcia etapu stabilizującego. ',

    'route-title/default': 'Dieta dr Ewy Dąbrowskiej',
    'route-title/day-plan': 'Dieta',
    'route-title/diet-intro': 'Wstęp',
    'route-title/day-plan-with-date': '',
    'route-title/diet-will-be-available-in-future':
        'Dieta będzie dostępna w przyszłości',
    'route-title/current-weight-needed': 'Dodaj aktualny pomiar',
    'route-title/goal-reached': 'Cel osiągnięty',
    'route-title/goal-lost': 'Cel utracony',
    'route-title/make-decision': 'Podejmij decyzję',
    'route-title/shopping-list': 'Lista zakupów',
    'route-title/measurements': 'Pomiary',
    'route-title/measurements-add': 'Dodaj pomiary',
    'route-title/measurements-edit': 'Edytuj pomiary',
    'route-title/diet-settings': 'Ustawienia diety',
    'route-title/settings': 'Ustawienia',
    'route-title/login-by-token': 'Login by Token',
    'route-title/logout': 'Logout',
    'route-title/error-404': 'Błąd 404',
    'route-title/unauthorized-exception': 'Brak dostępu',
    'route-title/content': '',
    'route-title/contact': 'Kontakt',
    'route-title/knowledge-category': 'Wiedza',
    'route-title/knowledge': 'Wiedza',
    'route-title/knowledge-article': '',
    'route-title/quiz': 'Quiz',
    'route-title/diet-outside-access': 'Brak dostępu',
    'route-title/block-cookies': '',
    'route-title/error-network': 'Wystąpił błąd sieci!',
    'route-title/activities': 'Moje aktywności',
    'route-title/activities-index': 'Aktywności',
    'route-title/activities-add': 'Dodaj aktywność',
    'route-title/activities-edit': 'Edytuj aktywność',
    'route-title/promo-code': 'Kupon rabatowy',
    'route-title/unsubscribe': 'Przykro nam, że odchodzisz',
    'route-title/error-application': 'Błąd aplikacji',
    'route-title/update': 'Zaktualizuj aplikację',
    'route-title/fav-meals': 'Ulubione posiłki',
    'mobile-title/knowledge': 'Wiedza',

    'alert/periodic-measurements-1':
        'Okresowe pomiary masy ciała są konieczne, aby śledzić postępy w realizacji celu.',
    'alert/periodic-measurements-2':
        'Przypominamy o regularnym uzupełnianiu swojej aktualnej masy ciała. Najlepiej zważ się jeszcze dziś!',

    'auth/confirm/header': 'Dziękujemy',
    'auth/confirm/content1': 'Twoje konto zostało aktywowane.',
    'auth/confirm/content2':
        ' Możesz zacząć korzystać z aplikacji mobilnej Dieta dr Dąbrowskiej.',
    'auth/confirm/mobileApps':
        'Nie masz aplikacji mobilnej? Ściągnij ją teraz!',

    'auth/login/title': 'Zaloguj się',
    'auth/login/with-google': 'Kontynuuj przez Google',
    'auth/login/with-facebook': 'Kontynuuj przez Facebooka',
    'auth/login/with-apple': 'Kontynuuj przez Apple',
    'auth/login/user-exists': 'Konto już istnieje. Użyj funkcji logowania.',
    'auth/login/user-not-exists':
        'Konto nie istnieje. Użyj funkcji rejestracji.',
    'auth/login/agreement':
        'Logowanie oznacza akceptację aktualnego regulaminu serwisu. Ostatnich zmian w regulaminie dokonano 20 grudnia 2019.',
    'auth/login/back-link-text': 'Powrót do logowania',
    'auth/login/menu-link-text': 'Zaloguj się',
    'auth/login/error-message': 'Logowanie nie powiodło się.',
    'auth/login/social-media-login-header':
        'Logowanie przez serwisy społecznościowe',
    'auth/login/social-media-set-up':
        'Twoje konto jest powiązane z serwisem społecznościowym.',

    'auth/remind-password/title': 'Odzyskaj dostęp',
    'auth/remind-password/description':
        'Wpisz e-mail, aby otrzymać wiadomość z instrukcją do zmiany hasła.',
    'auth/remind-password/link-text': 'Nie pamiętam swojego hasła',
    'auth/remind-password/error-message':
        'Nie znaleziono użytkownika o podanym adresie e-mail.',
    'auth/remind-password/success-message':
        'E-mail z instrukcją do zmiany hasła został wysłany.',

    'auth/reset-password/title': 'Ustaw nowe hasło',
    'auth/reset-password/token-is-not-valid-error-message':
        'Twój link do ustawiania hasła wygasł, jeśli nadal chcesz go użyć, skorzystaj ponownie z opcji "Nie pamiętam swojego hasła"',
    'auth/reset-password/error-message':
        'Hasło powinno mieć co najmniej 4 znaki.',
    'auth/reset-password/success-message':
        'Hasło do Twojego konta zostało ustawione.',

    'auth/password-is-not-set':
        'Nie masz jeszcze ustawionego hasła. Kliknij w przycisk poniżej aby ustawić hasło.',
    'auth/set-password': 'Ustaw hasło',
    'auth/set-password/title': 'Ustaw hasło',

    email: 'Adres email',
    password: 'Hasło',
    'password-to-login': 'Hasło do logowania',
    'new-password': 'Nowe hasło',
    name: 'Imię',

    'form/send': 'Wyślij',
    'validation/required': 'Pole jest wymagane.',
    'validation/date-format': 'Format daty jest niepoprawny.',
    'validation/email-format': 'Nieprawidłowy adres email.',
    'validation/url-format': 'Nieprawidłowy adres url.',

    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',

    'promo-code/page-title': 'Wpisz kupon rabatowy',
    'promo-code/input-label': 'Kupon rabatowy',
    'promo-code/missing-value': 'Wpisz kupon rabatowy',
    'promo-code/invalid-value': 'Niepoprawny kupon',
    'promo-code/submit': 'Weryfikuj kupon',
    'promo-code/not-exists': 'Kod promocyjny jest nie prawidłowy',
    'promo-code/has-been-activated': 'Kod promocyjny został aktywowany',

    'Something went wrong': 'Coś poszło nie tak',
    'Incident has been reported and we will work to solve it':
        'Incydent został zgłoszony, będziemy pracować nad jego rozwiązaniem.',
    'Go back': 'Wróć',
    'Try again': 'Spróbuj ponownie',

    'diet-faq/title': 'Pytania i odpowiedzi',

    'account/activate/title': 'Aktywacja konta',
    'account/activate/error': 'Aktywacja konta nie powiodła się.',

    'coupon/title': 'Aktywacja',
    'coupon/continue': 'Kontynuuj z kontem:',
    'coupon/register-user/title': 'Stwórz konto',
    'coupon/access-code': 'Kod dostępu',
    'coupon/login/title': 'Zaloguj się',
    'coupon/activate/title': 'Podaj swój kod dostępu do serwisu',
    'coupon/activated-successfully': 'Kupon został aktywowany',
    'coupon/activate-card/title': 'Aktywuj kod dostępu',
    'coupon/card-instruction': 'Aby skorzystać z karty dostępu:',
    'coupon/card-instruction/1': 'wypełnij wszystkie pola formularza',
    'coupon/card-instruction/2': 'przepisz kod ze swojej karty',
    'coupon/card-instruction/3':
        'jeśli wszystkie dane są poprawne, kliknij w przycisk.',
    'coupon/send': 'Aktywuj dostęp',

    'agreements/terms-and-conditions/text': 'Akceptuję ',
    'agreements/terms-and-conditions/text2': 'Regulamin',
    'agreements/terms-and-conditions/text3': ' oraz ',
    'agreements/terms-and-conditions/text4': 'Politykę prywatności',
    'agreements/terms-and-conditions/text5':
        ' i wyrażam zgodę na przetwarzanie moich danych osobowych.',
    'agreements/processing-personal-data/text':
        'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    'agreements/processing-personal-data/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',

    'newsletter/unsubscribe/title': 'Przykro nam, że odchodzisz',
    'newsletter/unsubscribe/success':
        'Adres email został wypisany z newslettera.',
    'newsletter/unsubscribe/send': 'Wypisz mnie',
    'read-more': 'Czytaj więcej',

    'cart/title': 'Koszyk',
    'cart/payment-method': 'Forma płatności',
    'cart/free-delivery-info':
        'Dodaj produkty za {{ price }}, aby mieć darmową wysyłkę.',
    'cart/delivery/heading': 'Adres dostawy',
    'cart/delivery/name': 'Imię i nazwisko lub nazwa firmy',
    'cart/delivery/street': 'Ulica, nr domu i lokalu',
    'cart/delivery/postal-code': 'Kod pocztowy',
    'cart/delivery/city': 'Miasto',
    'cart/delivery/phone': 'Numer telefonu',
    'cart/delivery/country': 'Kraj',
    'cart/summary': 'Podsumowanie zamówienia',
    'cart/summary/products': 'Produkty łącznie',
    'cart/summary/delivery': 'Dostawa',
    'cart/summary/total': 'Do zapłaty',
    'cart/pay': 'Zapłać',
    'cart/accept-condition/text':
        'Akceptuję Regulamin oraz politykę prywatności i wyrażam zgodę na przetwarzanie moich danych osobowych.',
    'cart/accept-condition/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',
    'cart/accept-condition/validation/required': 'Zgoda jest wymagana',
    'cart/upsell/you-save': 'Oszczędzasz',
    'cart/upsell/add-product': 'Dodaj do koszyka',
    'cart/upsell/added': 'Dodany',
    'cart/upsell/about': 'O produkcie',
    'cart/next': 'Dalej',
    'cart/go-to-payment': 'Przejdź do płatności >',
    'cart/safe-shopping': 'Bezpieczne zakupy',
    'cart/back-to-cart': 'Wróć do koszyka',
    'cart/confirm-and-order': 'Potwierdzam i zamawiam',
    'cart/register/login': 'Rejestracja i logowanie',
    'cart/change': 'zmień',
    'cart/order-summary': 'Podsumowanie zamówienia',
    'cart/address-of-delivery': 'Adres dostawy',
    'cart/payment': 'Płatność',
    'cart/payment/error-occured':
        'Przepraszamy, ale nie mogliśmy rozpocząć Twojej płatności. Spróbuj ponownie i daj nam znać jeśli problem będzie się powtarzał.',
    'cart/payment-cod/error-occured':
        'Przepraszamy, ale nie mogliśmy zrealizować Twojego zamówienia. Spróbuj ponownie i daj nam znać jeśli problem będzie się powtarzał.',
    'cart/order-is-pending': 'Zamówienie zostało przyjęte.',
    'cart/add/invalid-product-id':
        'Wybrany produkt nie jest dostępny w sprzedaży.',
    'cart/login-or': 'lub',

    'thank-you/title': 'Dziękujemy za zamówienie.',
    'thank-you/title/48hours': 'Twoje konto w aplikacji jest już aktywne.',
    'thank-you/title2': 'Co dalej?',
    'thank-you/how-to-activate-the-service': 'Jak aktywować usługę?',
    'thank-you/step1':
        'Odbierz maila, w którym znajdziesz potwierdzenie wraz z podsumowaniem swojego zamówienia.',
    'thank-you/step2':
        'Przystępujemy do przygotowania Twojego zamówienia do realizacji. Powiadomimy Cię mailem jak tylko paczka będzie gotowa do wysyłki.',
    'thank-you/step3':
        'W kolejnej wiadomości otrzymasz kod śledzenia wraz z instrukcją jak monitorować swoje zamówienie. Aż 91% przesyłek jest dostarczanych w ciągu 2 dni roboczych.',
    'thank-you/activation-code-and-instruction':
        'Kod aktywacyjny wraz z instrukcją, jak go użyć znajdziesz w przesyłce.',
    'thank-you/flyer-header': 'Dieta dr Ewy Dąbrowskiej - kod dostępu',
    'thank-you/flyer-content1':
        'Nie nastawiaj się na odchudzanie jak na męczarnię, którą trzeba z mozołem i bólem jakoś przetrwać.',
    'thank-you/flyer-content2':
        'Potraktuj zrzucanie zbędnych kilogramów, jako wyzwanie, któremu radośnie sprostasz.',
    'thank-you/flyer-content3':
        '– Zespół certyfikowanych dietetyków dr Dąbrowskiej',
    'thank-you/flyer-footer1': 'Wejdź na stronę ',
    'thank-you/flyer-footer2': ', wpisz poniższy kod i aktywuj usługę.',
    'thank-you/go-to-diet-plan': 'Przejdź do planu diety',
    'thank-you/go-to-diet-plan/content':
        'Twój plan diety jest już ułożony. Jedz pysznie każdego dnia i osiągnij swój cel!',
    'thank-you/start-configuring-your-diet':
        'Rozpocznij konfigurowanie Twojej diety',
    'thank-you/start-configuring-your-diet/content':
        'Już zaraz będziesz mógł rozpoczać swoją Dietę! Zanim się to stanie, potrzebujemy od Ciebie jeszcze kilku dodatkowych informacji.',
    'thank-you/start-configuring-your-diet/button': 'Konfiguruj dietę',
    'thank-you/warning':
        'UWAGA! Aby w pełni korzystać z zasobów serwisu aktywuj swoje konto. Link aktywacyjny wysłaliśmy Tobie na adres: {{email}}. Jeżeli nie [otrzymałaś|otrzymałeś] wiadomości sprawdź folder spam.',
    'loading/message': 'Ładowanie…',
    'et-al/abbrev': 'itp',
    'et-al/full': 'i tym podobne',

    'payment/pending/title': '[Zamówiłaś|Zamówiłeś] pakiet: {{ products }}',
    'payment/pending/description':
        'Dostęp do Twojej usługi otrzymasz, gdy zaksięgujemy Twoją wpłatę. Czas oczekiwania zależy od wybranego przez Ciebie sposobu płatności.',
    'payment/pending/make-payment-description':
        'Jeżeli nie [dokonałaś|dokonałeś] jeszcze płatności, możesz to zrobić teraz.',
    'payment/pending/make-payment': 'Zapłać teraz',

    'message/showing-cached-data': 'Dane mogą nie być aktualne.',
    'message/showing-cached-data/network-error':
        'Nie udało się pobrać danych — pokazane dane mogą być nieaktualne.',
    'message/showing-cached-data/network-error-offline':
        'Brak połączenia z Internetem — pokazane dane mogą być nieaktualne.',

    'units/mililiter/full': 'mililitr(ów)',
    'units/mililiter/full_0': 'mililitr',
    'units/mililiter/full_1': 'mililitry',
    'units/mililiter/full_2': 'mililitrów',
    'units/mililiter/full_3': 'mililitra',
    'units/mililiter/abbr': 'ml',

    'units/ounce/full': 'uncji',
    'units/ounce/full_0': 'uncja',
    'units/ounce/full_1': 'uncje',
    'units/ounce/full_2': 'uncji',
    'units/ounce/full_3': 'uncji',
    'units/ounce/abbr': 'oz',

    'route-title/hydration-day': 'Twoje nawodnienie',
    'menu/hydration': 'Nawodnienie',
    'menu/your-hydration': 'Twoje nawodnienie',
    'hydration/add-custom': 'Dodaj własną porcję wody',
    'hydration/goal-label': 'Indywidualny cel:',
    'hydration/goal-changer-label': 'Indywidualny cel nawodnienia',
    'hydration/goal-reached-label': 'Gratulacje!',
    'hydration/goal-reached':
        'Osiągnąłeś swój dzienny cel nawodnienia. Pamiętaj, dobre nawodnienie przyczynia się do prawidłowego funkcjonowania organizmu.',
    'hydration/goal-left/past/pre-value':
        '{{date}} [powinnaś była|powinieneś był] wypić jeszcze',
    'hydration/goal-left/past/post-value': 'wody',
    'hydration/goal-left/present/pre-value':
        'Dzisiaj [powinnaś|powinieneś] wypić jeszcze',
    'hydration/goal-left/present/post-value': 'wody',
    'hydration/goal-left/future/pre-value':
        '{{date}} [powinnaś|powinieneś] wypić',
    'hydration/goal-left/future/post-value': 'wody',
    'hydration/progress/label': 'wypite',
    'hydration/remove-last-portion': 'Usuń ostatnią porcję wody',
    'route-title/hydration-add': 'Dodaj porcję wody',
    'hydration/add-portion': 'Dodaj porcję',
    'hydration/add/heading': 'Ile wody [wypiłaś|wypiłeś]?',
    'hydration/add/submit': 'Dodaj',
    'hydration/add/cancel': 'Anuluj',
    'hydration/add/custom': 'własne',
    'hydration/full-glass': 'Pełna szklanka',
    'hydration/full-bottle': 'Pełna butelka',
    'hydration/add-global-action': 'Dodaj wypitą dziś porcję wody',
    'route-title/hydration-history': 'Historia nawadniania',
    'menu/hydration-history': 'Historia',
    'hydration/go-to-history': 'Historia',
    'hydration/back-to-hydration': 'Wróć',
    'hydration/recommended-value': '{{value}} (rekomendowane)',
    'hydration/glass-value': '{{value}} (szklanka)',
    'hydration/glass': 'szklanka',
    'hydration/half-bottle-value': '{{value}} (pół butelki)',
    'hydration/bottle-value': '{{value}} (butelka)',
    'hydration/bottle': 'butelka',
    'default-value': 'Wartość domyślna',
    'individual-goal': 'Indywidualny cel',

    'fav-meals/add-to-favourites': 'Dodaj do ulubionych',
    'fav-meals/is-in-favourites': 'Ulubione',
    'fav-meals/added': 'Posiłek został dodany do ulubionych.   ',
    'fav-meals/removed': 'Usunięto z ulubionych.',
    'fav-meals/back': 'Cofnij',
    'fav-meals/added-to-dayplan': 'Posiłek dodany do jadłospisu.',
    'fav-meals/insert-into-dayplan': 'Wstaw posiłek do jadłospisu',
    'fav-meals/all': 'Wszystkie',

    'terms-of-service/title': 'Regulaminy serwisu i aplikacji',
    'terms-of-service/web': 'Zakup za pośrednictwem strony internetowej',
    'terms-of-service/android': 'Zakup w Google Play',
    'terms-of-service/ios': 'Zakup w App Store',

    'intetmittent-fasting/end-of-post':
        'Cel postu osiągnięty. Zakończ trwający post.',
    'intetmittent-fasting/next-eat-window-today':
        'Następne okno żywieniowe dziś',
    'intetmittent-fasting/next-eat-window-tomorrow':
        'Następne okno żywieniowe jutro',
    'intetmittent-fasting/eat-window-to': 'Okno żywieniowe do',

    'developer-options/api-gateway': 'API Gateway',
    'developer-options/set-api-gateway': 'Ustaw API Gateway',
    'developer-options/api-gateway-changed': 'API Gateway zostało zmienione',
    'developer-options/clear-data': 'Wyczyść dane',
    'developer-options/clear-apollo-cache': 'Wyczyść Apollo Cache',
    'developer-options/apollo-cache-cleared':
        'Apollo Cache został wyczyszczony',
    'developer-options/clear-local-storage': 'Wyczyść Local Storage',
    'developer-options/local-storage-cleared':
        'Local Storage został wyczyszczony',
    'developer-options/remove-service-worker': 'Usuń Service Worker',
    'developer-options/service-worker-removed':
        'Service Worker został wyrejestrowany',
    'developer-options/create-new-user': 'Utwórz nowego użytkownika',
    'developer-options/new-user-created': 'Nowy użytkownik został utworzony',
    'developer-options/add-access': 'Dodaj dostęp',
};
